<script>
import { user } from "@/config/api/users";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      email: "",
      submitted: false,
      error: null,
      tryingToReset: false,
      isResetError: false,
      sendingRequest: false,
    };
  },
  validations: {
    email: { required, email },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const api = user.resetPassword.get;
        api.id = this.email;
        this.sendingRequest = true;
        this.generateAPI(api)
          .then((res) => {
            console.log(res.data);
            this.$bvToast.toast(
              "Password reset successfully!,Please check your email.",
              {
                title: "Password reset",
                variant: "success",
                solid: true,
              }
            );
          })
          .catch((err) => {
            this.$bvToast.toast(err.response.data.error, {
              title: "Password reset",
              variant: "danger",
              solid: true,
            });
          })
          .finally(() => {
            this.sendingRequest = false;
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <div
              class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              "
              style="max-width: 548px; margin: auto"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to B-SEEN.</p>
                      </div>

                      <div class="p-2 mt-5">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                          >{{ error }}</b-alert
                        >

                        <form
                          class="form-horizontal"
                          @submit.prevent="tryToReset"
                        >
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="useremail">Email</label>
                            <input
                              v-model="email"
                              type="email"
                              class="form-control"
                              id="useremail"
                              placeholder="Enter email"
                              :class="{
                                'is-invalid': submitted && $v.email.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.email.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.email.required"
                                >Email is required.</span
                              >
                              <span v-if="!$v.email.email"
                                >Please enter valid email.</span
                              >
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              "
                              type="submit"
                            >
                              <span v-if="sendingRequest">Please wait...</span>
                              <span v-else> Reset</span>
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          Already know password ?
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Log in</router-link
                          >
                        </p>
                        <p>© 2022 B-SEEN</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>